const protectedPages = [
  { path: "users" },
  { path: "profile" },
  { path: "home" },
  { path: "units-details" },
  { path: "property-profile" },
  { path: "orders" },
  { path: "add-unit" },
  { path: "add-contract" },
  { path: "contracts" },
  { path: "sales-invoices" },
  { path: "sanadat" },
  { path: "add-sanadat" },
  { path: "settings" },
  { path: "support-center" },
  { path: "add-property" },
  { path: "properties" },
  { path: "customers" },
  { path: "employees" },
  { path: "services" },
  { path: "banks" },
  { path: "purchase-invoices" },
  { path: "purchase-order-request" },
  { path: "purchase-return-invoices" },
  { path: "entities" },
  { path: "add-catch-sanadat" },
  { path: "financial-entries" },
  { path: "contracts-conditions" },
  { path: "employee-positions" },
  { path: "financial-trees" },
  { path: "transactions-reasons" },
  { path: "inventory" },
  { path: "add-inventory" },
  { path: "inventory-types" },
  { path: "reports" },
  { path: "reports/inventory/movements" },
  { path: "units" },
  { path: "tasks" },
  { path: "permissions" },
  { path: "system-administrators" },
  { path: "notes" },
  { path: "units-messages" },
  { path: "access-denied" },
  { path: "add-purchase-rpo" },
  { path: "crm/potential-customers" },
  { path: "crm/reports" },
];

const others = [
  { path: "make-promotion/:id", filePath: "make-promotion" },
  { path: "potential-customers/:id", filePath: "potential-customers-profile" },
  { path: "add-potential-customers/:id", filePath: "add-potential-customers" },
  {
    path: "property-profile/:id",
    filePath: "property-profile",
  },
  {
    path: "employee-profile/:id",
    filePath: "employee-profile",
  },
  {
    path: "customer-profile/:id",
    filePath: "customer-profile",
  },
  {
    path: "unit-profile/:id",
    filePath: "unit-profile",
  },
  {
    path: "inventory-profile/:id",
    filePath: "inventory-profile",
  },
  {
    path: "properties/:id",
    filePath: "add-property",
  },
  {
    path: "units/:id",
    filePath: "add-unit",
  },
  {
    path: "employees/:id",
    filePath: "employees/add-employee",
  },
  { path: "add-customer", filePath: "customers/add-customer" },
  { path: "add-employee", filePath: "employees/add-employee" },
  { path: "edit-customer/:id", filePath: "customers/add-customer" },
  { path: "add-inventory/:id", filePath: "add-inventory" },
  { path: "support-details/:id", filePath: "support-details" },
  { path: "add-condition", filePath: "contracts-conditions/add" },
  { path: "add-condition/:id", filePath: "contracts-conditions/add" },
  { path: "add-contract/:id", filePath: "add-contract" },
  { path: "payouts", filePath: "employees/payouts" },
  { path: "invoice/:id", filePath: "invoice-view" },
  { path: "purchase-invoice/:id", filePath: "invoice-purchase-view" },
  { path: "add-unit/:id", filePath: "add-unit/add-unit-by-property" },
  { path: "add-contract-unit/:unit_id", filePath: "add-contract" },
  { path: "add-purchase-invoice/:type", filePath: "add-purchase-invoice" },
  { path: "add-sales-invoice/:type", filePath: "add-sales-invoice" },
  { path: "rpo/:id", filePath: "rpo-view" },
  {
    path: "reports/financial-statements/:type",
    filePath: "reports/financial-statements",
  },
  {
    path: "reports/taxes",
    filePath: "reports/taxes",
  },
  {
    path: "reports/sales-statements/:type",
    filePath: "reports/sales-statements",
  },
  {
    path: "reports/employees",
    filePath: "reports/employees",
  },
  {
    path: "reports/purchase-statements/:type",
    filePath: "reports/purchase-statements",
  },
  {
    path: "task/:id",
    filePath: "tasks/task",
  },
  {
    path: "add-admin",
    filePath: "system-administrators/add-admin",
  },
  {
    path: "update-admin/:id",
    filePath: "system-administrators/add-admin",
  },
  {
    path: "add-permission",
    filePath: "permissions/add-permission",
  },
  {
    path: "update-permission/:id",
    filePath: "permissions/add-permission",
  },
  {
    path: "update-profile/:id",
    filePath: "system-administrators/add-admin",
  },
  { path: "sanadat/:id", filePath: "update-sanadat" },
  { path: "add-credit-note/:id", filePath: "add-credit-note" },
  { path: "add-debit-note/:id", filePath: "add-debit-note" },
  { filePath: "sales-return-invoices", path: "sales-return-invoices/:type" },
  {
    filePath: "purchase-return-invoices",
    path: "purchase-return-invoices/:type",
  },
];
export const protectedRoutes = [
  ...others,
  ...protectedPages.map((p) => ({
    path: p.path,
    filePath: p.filePath ? p.filePath : p.path,
  })),
];
